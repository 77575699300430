import React from "react";
import ExceptionDetailsIFrame from "./ExceptionDetailsIFrame";
import { useSelector } from "react-redux";
import { Style } from "@mui/icons-material";

const Pivision = () => {
  return (
    <div className={`product-manage-section`}>
      <div className="wrap">
        <div className="cols cols2">
          <div className="col">
            <ExceptionDetailsIFrame
              pivision_url={"https://192.168.1.129/PIVision/#/Displays/120151/AHM"}
            />

            {/* <img
              style={{ width: "100%", height:"500px" }}
              src={
                window.location.origin + "/images/web_images/pivision_img.png"
              }
              alt="maintainance_chart"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pivision;
