import { createAsyncThunk } from "@reduxjs/toolkit";
import Papa from "papaparse";

export const fetchExceptionsThunk = createAsyncThunk(
  "dashboard/fetchExceptions",
  (payload) => {
    return new Promise((resolve, reject) => {
      Papa.parse(payload, {
        download: true,
        header: true,
        dynamicTyping: true,
        error: (error) => {
          console.error("Error:", error);
          reject(error);
        },
        complete: (result) => {
          if (result && result.data.length > 0) {
            resolve(result.data);
          } else {
            reject("No data found in CSV");
          }
        },
      });
    });
  }
);

export const fetchFleetsThunk = createAsyncThunk(
  "dashboard/fetchFleets",
  (payload) => {
    return new Promise((resolve, reject) => {
      Papa.parse(payload, {
        download: true,
        header: true,
        dynamicTyping: true,
        error: (error) => {
          console.error("Fleet Error:", error);
          reject(error);
        },
        complete: (result) => {
          if (result && result.data.length > 0) {
            resolve(result.data);
          } else {
            reject("No data found in CSV");
          }
        },
      });
    });
  }
);

export const fetchWorkOrdersThunk = createAsyncThunk(
  "dashboard/fetchWorkOrders",
  (payload) => {
    return new Promise((resolve, reject) => {
      Papa.parse(payload, {
        download: true,
        header: true,
        dynamicTyping: true,
        error: (error) => {
          console.error("Work Order Error:", error);
          reject(error);
        },
        complete: (result) => {
          if (result && result.data.length > 0) {
            resolve(result.data);
          } else {
            reject("No data found in CSV");
          }
        },
      });
    });
  }
);
export const fetchRecommedationThunk = createAsyncThunk(
  "dashboard/fetchRecommedationThunk",
  (payload) => {
    return new Promise((resolve, reject) => {
      Papa.parse(payload, {
        download: true,
        header: true,
        dynamicTyping: true,
        error: (error) => {
          console.error("Error:", error);
          reject(error);
        },
        complete: (result) => {
          if (result && result.data.length > 0) {
            resolve(result.data);
          } else {
            reject("No data found in CSV");
          }
        },
      });
    });
  }
);

export const fetchConMonThunk = createAsyncThunk(
  "dashboard/fetchConMon",
  (payload) => {
    return new Promise((resolve, reject) => {
      Papa.parse(payload, {
        download: true,
        header: true,
        dynamicTyping: true,
        error: (error) => {
          console.error("Error:", error);
          reject(error);
        },
        complete: (result) => {
          if (result && result.data.length > 0) {
            resolve(result.data);
          } else {
            reject("No data found in CSV");
          }
        },
      });
    });
  }
);
export const fetchMachineEventThunk = createAsyncThunk(
  "dashboard/fetchMachineEvent",
  (payload) => {
    return new Promise((resolve, reject) => {
      Papa.parse(payload, {
        download: true,
        header: true,
        dynamicTyping: true,
        error: (error) => {
          console.error("Error:", error);
          reject(error);
        },
        complete: (result) => {
          if (result && result.data.length > 0) {
            resolve(result.data);
          } else {
            reject("No data found in CSV");
          }
        },
      });
    });
  }
);

// Update Data EndPoint

export const setExceptionData = createAsyncThunk(
  "dashboard/setExceptionData",
  (payload) => {
    return payload
  }
);
export const setRecomendationData = createAsyncThunk(
  "dashboard/setRecomendationData",
  (payload) => {
    return payload
  }
);
