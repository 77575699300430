import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import EquipmentDetails from "../../ExceptionDetailsComponents/EquipmentDetails/EquipmentDetails";
import { AppMenuTrigger } from "../../../layouts/AppMenuTrigger";
import UploadFilesComponent from "../../Common/UploadFilesComponent";
import Header from "../header/Header";
import {
  AddEditComment,
  ProductInfoSection,
  RecommendationForm,
  RelatedRecommendationsTable,
} from "../../RecommendationComponent";
import ExceptionDetailsComments from "../../ExceptionDetailsComponents/ExceptionDetailsComments/ExceptionDetailsComments";
import FleetDetail from "../exceptions/FleetDetail";
import { fetchRecommedationThunk } from "../../../redux/v2/dashbaord/DashboardThunk";
import { CSVPath } from "../../../Data/allCSVPath";

// const MySwal = withReactContent(Swal);

const RecommendationPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { exceptionId } = useParams();
  const [fleet, setfleet] = useState({});
  const [exception, setException] = useState({});
  const fleets = useSelector((state) => state.dashboardReducer.fleets);
  const exceptions = useSelector((state) => state.dashboardReducer.exceptions);
  const recomandation = useSelector(
    (state) => state.dashboardReducer.recommedations
  );
  useEffect(() => {
    const exceptionDetail = exceptions.find(
      (exception) => exception.id == exceptionId
    );
    if (exceptionDetail) {
      setException(exceptionDetail);
      const fleetDetail = fleets.find(
        (fleet) => fleet.asset_name == exceptionDetail.asset_name
      );
      if (fleetDetail) {
        setfleet(fleetDetail);
      }
    }
  }, [exceptions, fleets]);
  const [visiblityStatus, setVisiblityStatus] = useState(false);
  const [searchVisiblity, setSearchVisiblity] = useState(false);

  return (
    <>
      {/* <Header
        subHeader={<ExceptionDetailSubheader />}
        // handleManuClick={handleManuClick}
        headerIcon={window.location.origin + "/images/recommendation-icon.svg"}
        heading="Recommendation"
      /> */}
      <div className="dash-header">
        <Header dateRangeFilter={[]}  BackArrow/>
      </div>
      {/* <EquipmentDetails /> */}
      {/* <FleetDetail exceptions={exceptions} fleets={fleets}/> */}
      <FleetDetail
        fleet={fleet}
        exception={exception}
        isRecommedation
        recomandation={recomandation}
      />
      <div id="main" className={`${visiblityStatus ? "app-btn-active" : ""}`}>
        {/* {visiblityStatus ? (
          <AppMenuTrigger className={`${visiblityStatus ? "fadeIn" : ""}`} />
        ) : ( */}
        <>
          {/* <ProductInfoSection /> */}
          <div className="recommendation-section margin-bottom">
            <div className="dashboard-container pl-20 pr-20 py-20">
              <RecommendationForm
                fleet={fleet}
                exception={exception}
                isRecommedation
              />
            
            </div>
          </div>
        </>
        {/* )} */}
      </div>
      {/* {visiblityStatus || visiblityStatus ? (
        <div className="modal-backdrop fade show"></div>
      ) : null} */}
    </>
  );
};

export default RecommendationPage;
