import React, { useEffect, useState } from "react";
import ExceptionCard from "../header/ExceptionCard";
import { ExceptionDetails } from "../header/ExceptionDetails";
import MachineCard from "../header/MachineCard";
import CountCard from "../header/CountCard";
import Header from "../header/Header";
import "./Dashboard.css";
import {
  functionCalExceptionStatus,
  functionCalExceptions,
  functionCalWatchList,
  functionMachineException,
  functionMachineWatchList,
  getMaintainanceData,
} from "../../../utils/functions";
import { ExceptionChart } from "../header/ExceptionChart";
import { LocalStorageConstant } from "../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchExceptionsThunk,
  fetchFleetsThunk,
  fetchWorkOrdersThunk,
} from "../../../redux/v2/dashbaord/DashboardThunk";
import { CSVPath } from "../../../Data/allCSVPath";
import { height } from "@mui/system";
import { CategorywiseTables } from "../../SummaryPageComponent";
import MaintainanceChart from "../chart/MaintainanceChart";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const [csvData, setCsvData] = useState([]);
  const [exceptionData, setexceptionData] = useState([]);
  const [exceptionStatus, setexceptionStatus] = useState([]);
  const [watchListData, setwatchListData] = useState([]);
  const [maintainanceData, setMaintainanceData] = useState([]);
  const dispatch = useDispatch();

  const Exceptions = useSelector((state) => state.dashboardReducer.exceptions);
  const workOrders = useSelector((state) => state.dashboardReducer.work_orders);

  useEffect(() => {
    // dispatch(fetchFleetsThunk(process.env.PUBLIC_URL + CSVPath.FleetList));
    // dispatch(fetchExceptionsThunk(process.env.PUBLIC_URL + CSVPath.Exceptions));
    // dispatch(
    //   fetchWorkOrdersThunk(process.env.PUBLIC_URL + CSVPath.WorkOrderList)
    // );
  }, [dispatch]);
  const handleExceptionClick = (event) => {
    navigate(`/exception-detail/${event.id}`);
  };
  useEffect(() => {
    setCsvData([...Exceptions]);

    const getDateRangeFilter = JSON.parse(
      localStorage.getItem(LocalStorageConstant.DASHBOARD_DATERANGE_FILTER)
    );
    if (getDateRangeFilter && getDateRangeFilter.length > 0) {
      handleDateRangeFilter(getDateRangeFilter, true);
    }
    const getSeverityFilter = JSON.parse(
      localStorage.getItem(LocalStorageConstant.DASHBOARD_SEVERITY_FILTER)
    );
    if (getSeverityFilter) {
      handleSeverityFilter(getSeverityFilter);
    }
    const exceptionData1 = functionCalExceptions(Exceptions);
    const exceptionStatus1 = functionCalExceptionStatus(Exceptions);
    const watchListData1 = functionCalWatchList(workOrders);

    if (exceptionData1.length > 0) {
      setexceptionData([...exceptionData1]);
    }
    if (exceptionStatus1.length > 0) {
      setexceptionStatus([...exceptionStatus1]);
    }
    if (watchListData1.length > 0) {
      setwatchListData([...watchListData1]);
    }
  }, [Exceptions]);

  const handleMachineClick = (machineId) => {
    const exceptionData2 = functionMachineException(
      csvData,
      exceptionData,
      machineId
    );
    const watchListData2 = functionMachineWatchList(
      workOrders,
      watchListData,
      machineId
    );
    setwatchListData([...watchListData2]);
    setexceptionData([...exceptionData2]);
    const maintainance = getMaintainanceData(csvData, machineId);
    setMaintainanceData([...maintainance]);
  };

  const handleDateRangeFilter = (dateRange, is_intial = false) => {
    let startDay = new Date();
    let endDay = new Date();
    startDay.setDate(startDay.getDate() - dateRange[0]);
    endDay.setDate(endDay.getDate() - dateRange[1]);
    let filteredData = Exceptions.filter((item) => {
      const eventTime = new Date(item.event_start_time);
      return eventTime >= endDay && eventTime <= startDay;
    });
    setCsvData([...filteredData]);
    if (!is_intial) {
      localStorage.setItem(
        LocalStorageConstant.DASHBOARD_DATERANGE_FILTER,
        JSON.stringify(dateRange)
      );
    }
  };
  const handleSeverityFilter = (data, key = null) => {
    let trueKeys = [];
    if (key) {
      if (data.hasOwnProperty(key)) {
        data[key] = !data[key];
      }
      trueKeys = Object.keys(data).filter((key) => data[key]);
    } else {
      trueKeys = Object.keys(data).filter((key) => data[key]);
    }
    let filterData = [];
    if (trueKeys.length) {
      filterData = Exceptions.filter((item) => {
        return trueKeys.includes(item.event_severity);
      });
    } else {
      filterData = Exceptions;
    }
    setCsvData([...filterData]);
    if (key) {
      localStorage.setItem(
        LocalStorageConstant.DASHBOARD_SEVERITY_FILTER,
        JSON.stringify(data)
      );
    }
  };

  return (
    <>
      <div className="dash-header">
        <Header dateRangeFilter={handleDateRangeFilter} showDateFilter />
      </div>
      <div className="dash-count">
        <CountCard
          handleSeverityChange={handleSeverityFilter}
          data={Exceptions}
        />
      </div>
      <div className="dash-exception">
        <div className="row sub-section">
          <div className="col-md-4 subsec1">
            <MachineCard
              data={csvData}
              handleClick={(machineId) => handleMachineClick(machineId)}
            />
          </div>
          <div className="col-md-3 subsec2">
            {exceptionData && (
              <ExceptionCard
                chartData={exceptionData}
                statusCount={exceptionStatus}
                heading={"Exceptions"}
              />
            )}
          </div>
          <div className="col-md-3 subsec3">
            {exceptionData && (
              <ExceptionCard
                chartData={watchListData}
                statusCount={exceptionStatus}
                watchListData={watchListData}
                heading={"CMA Watch List"}
              />
            )}
          </div>
        </div>
      </div>
      <div className="dash-maintainance-chart">
        {/* <ExceptionChart /> */}
        <MaintainanceChart data={maintainanceData} />
        {/* <img
          style={{ width: "100%", height: "10%" }}
          src={
            window.location.origin + "/images/web_images/maintenance_chart.png"
          }
          alt="maintainance_chart"
        /> */}
      </div>
      <div className="dash-exception-data-table">
        <CategorywiseTables
          data={Exceptions}
          type="Exceptions"
          hideEquipmentType
          hideModel
          // hideEquipmentId
          handleExceptionClick={handleExceptionClick}
          // hideActions
          hideREMSEvent
          hideIsTaco
          // hideSnooze
          hideTillDate
          hideAnalyticStatus
        />
        {/* <ExceptionDetails data={Exceptions} /> */}
      </div>
    </>
  );
};

export default Dashboard;
