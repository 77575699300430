import React, { useState } from "react";
import ExceptionTab from "../exception_tab/ExceptionTab";

const EquipmentManagement = ({ selectedEquipment, selectedException }) => {
  const showIframeExtend = false;

  return (
    <div
      className={`product-manage-section ${
        showIframeExtend ? "full-screen" : ""
      }`}
    >
      <div className="wrap">
        <div className="cols cols2">
          <div className="col">
            {!showIframeExtend && (
              <div className="product-manage-box">
                <ExceptionTab
                  selectedEquipment={selectedEquipment}
                  selectedException={selectedException}
                />
              </div>
            )}

            {/* {showIframeExtend && (
            <ExceptionDetailsIFrame
              pivision_url={selectedException?.pivision_url}
            />
          )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EquipmentManagement;
