import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import API from "../../../API/API";
import DatePicker from "react-datepicker";
import { fetchExceptionDocuments } from "../../../redux/exception-details/exceptionDetailsThunks";
import "react-datepicker/dist/react-datepicker.css";
import "./ManagementTab.scss";
import Popup from "reactjs-popup";
// import UpsertConMon from "../UpsertConMon/UpsertConMon";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchExceptionsThunk,
  setExceptionData,
} from "../../../redux/v2/dashbaord/DashboardThunk";

const MySwal = withReactContent(Swal);
let errorStyle = {
  border: "1px solid red",
};
let errorHelpTextStyle = {
  color: "red",
  marginLeft: "10px",
};

const addDays = (days) => {
  let DateObj = new Date();
  DateObj.setDate(DateObj.getDate() + days);
  return DateObj;
};

const statusReasonMapping = {
  S1: [],
  S2: ["R3", "R4", "R5", "R6", "R7"],
  S3: ["R0", "R4", "R5", "R8", "R9"],
  S4: [],
  S5: [],
};


const ManagementTab = ({ selectedEquipment, selectedException }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    statusId: "",
    selectedStatus: "",
    oldStatus: "",
    reasonCode: "",
    toDate: "",
    otherReasonCode: "",
    submitted: false,
    conmonItem: false,
  });

  const [errors, setErrors] = useState({
    selectedStatus: {
      isValid: false,
      message: "",
    },
    reasonCode: {
      isValid: false,
      message: "",
    },
    selectedConmon: {
      isValid: false,
      message: "",
    },
  });

  const [showDatePicker, setShowDatePicker] = useState(false);

  const userPreferences = useSelector(
    (state) => state.userPreferencesReducer.userPreferences
  );

  const staticOptions = [
    { code: "S1", name: "Accept" },
    { code: "S2", name: "Snooze/Monitor" },
    { code: "S3", name: "Close" },
    { code: "S5", name: "Complete" },
  ];

  let exceptionReasonValues = [
    { code: "R0", name: "AHS Review Required" },
    { code: "R3", name: "Monitoring" },
    { code: "R4", name: "Pending fixes by maint" },
    { code: "R5", name: "Early Sign/Intermettent" },
    { code: "R6", name: "Low priority issues" },
    { code: "R7", name: "Other" },
    { code: "R8", name: "Fault Cleared" },
    { code: "R9", name: "Next Service Soon" },
  ];

  let Exceptions = useSelector((state) => state.dashboardReducer.exceptions);

  const validateForm = (submitted) => {
    const { selectedStatus, reasonCode, otherReasonCode, toDate } = formData;
    let retData = {
      selectedStatus: {
        isValid: false,
        message: "",
      },
      reasonCode: {
        isValid: false,
        message: "",
      },
      selectedConmon: {
        isValid: false,
        message: "",
      },
    };
    if (submitted) {
      if (!selectedStatus) {
        retData.selectedStatus = {
          isValid: true,
          message: "This field is required.",
        };
      } else if (selectedStatus === "S2" && !toDate) {
        retData.toDate = {
          isValid: true,
          message: "Date is required.",
        };
      }
      if (!reasonCode && selectedStatus !== "S1" && selectedStatus !== "S5") {
        retData.reasonCode = {
          isValid: true,
          message: "This field is required.",
        };
      } else if (
        reasonCode === "R4" &&
        !otherReasonCode &&
        selectedStatus === "S2"
      ) {
        retData.custom_reason_code = {
          isValid: true,
          message: "This field is required.",
        };
      } else if (
        reasonCode &&
        !statusReasonMapping[selectedStatus].includes(reasonCode)
      ) {
        retData.reasonCode = {
          isValid: true,
          message: "Plesase select appropriate reason.",
        };
      }
    }
    return retData;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validation = validateForm(true);
    setErrors(validation);
    if (
      validation.reasonCode.isValid ||
      validation.selectedStatus.isValid ||
      validation.toDate ||
      validation.custom_reason_code
    ) {
      return;
    } else {
      const { selectedStatus, oldStatus, reasonCode, otherReasonCode, toDate } =
        formData;

      const data = {
        exception_id: selectedException.id,
        status: selectedStatus,
        old_status: oldStatus ? oldStatus : null,
        reason_code: reasonCode,
        custom_reason_code: otherReasonCode ? otherReasonCode : null,
        till_date: toDate ? toDate : null,
        user_id: userPreferences.user_id ? userPreferences.user_id : null,
      };
      let isChange = false;
      if (Exceptions && Exceptions.length > 0) {
        let deepExceptions = JSON.parse(JSON.stringify(Exceptions));
        deepExceptions.forEach((item) => {
          if (item.id == selectedException.id) {
            isChange = true;
            if (selectedStatus) {
              item.exception_status = selectedStatus;
            }
            if (reasonCode) {
              item.exception_status_reason_code = reasonCode;
            }
            if (toDate) {
              item.exception_status_till_date = toDate;
            }
          }
          return item;
        });
        if (isChange) {
          dispatch(setExceptionData(deepExceptions));
          MySwal.fire(
            "Updated!",
            "Exception status has been updated.",
            "success"
          );
          navigate("/");
        } else {
          MySwal.fire("Error!", "Exception status update Failed.", "error");
        }
      }
    }
  };

  const handleStatusChange = (e) => {
    let toDate = "";
    if (e.target.value == "S2") {
      setShowDatePicker(true);
      toDate = moment().format("YYYY-MM-DD");
    } else {
      errors.toDate && delete errors.toDate;
      setShowDatePicker(false);
    }
    if (e.target.value) {
      setErrors({
        ...errors,
        selectedStatus: {
          isValid: false,
          message: "",
        },
      });
    }
    setFormData({
      ...formData,
      selectedStatus: e.target.value,
      toDate: toDate,
      reasonCode: "",
      otherReasonCode: "",
    });
  };

  const handleReasonCodeChange = (e) => {
    errors.custom_reason_code && delete errors.custom_reason_code;
    if (e.target.value) {
      setErrors({
        ...errors,
        reasonCode: {
          isValid: false,
          message: "",
        },
      });
    }
    setFormData({
      ...formData,
      reasonCode: e.target.value,
      otherReasonCode: "",
    });
  };

  return (
    <div className="form-box">
      <div
        className="tab-main-content"
        style={{ height: formData.selectedStatus === "S1" ? "394px" : "330px" }}
      >
        <form onSubmit={handleSubmit} id="StatusForm">
          <div className="form-block">
            <div className="row">
              <div className="col-md-5">
                <div className="form-group">
                  <label>Exception Status</label>
                  <div className="custom-select">
                    <input type={"hidden"} value={formData.statusId}></input>
                    <select
                      value={formData.selectedStatus}
                      style={errors.selectedStatus.isValid ? errorStyle : {}}
                      onChange={handleStatusChange}
                    >
                      <option value="">Select Status</option>
                      {staticOptions.map((status, index) => (
                        // availableStatus.includes(status?.code) ? (
                        <option value={status.code} key={index}>
                          {status.name}
                        </option>
                      ))}
                      ;
                    </select>
                    <span
                      style={
                        errors.selectedStatus.isValid
                          ? errorHelpTextStyle
                          : { display: "none" }
                      }
                    >
                      {errors.selectedStatus.message}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="form-group">
                  <span style={showDatePicker ? {} : { display: "none" }}>
                    <label>Snooze Until</label>
                    <div>
                      <DatePicker
                        style={
                          errors.toDate && errors.toDate.isValid
                            ? errorStyle
                            : {}
                        }
                        selected={
                          Date.parse(formData.toDate) || Date.parse(new Date())
                        }
                        onChange={(date) => {
                          if (date) {
                            errors.toDate && delete errors.toDate;
                            setErrors(errors);
                          }
                          setFormData({ ...formData, toDate: date });
                        }}
                        placeholderText="Select Date"
                        dateFormat="yyyy-MM-dd"
                        minDate={new Date()}
                        maxDate={addDays(30)}
                      />
                    </div>

                    <span
                      style={
                        errors.toDate && errors.toDate.isValid
                          ? errorHelpTextStyle
                          : { display: "none" }
                      }
                    >
                      {errors.toDate ? errors.toDate.message : ""}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            {(formData.selectedStatus === "S2" ||
              formData.selectedStatus === "S3") && (
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <label>Reason</label>
                    <div className="custom-select">
                      <select
                        value={formData.reasonCode}
                        style={errors.reasonCode.isValid ? errorStyle : {}}
                        onChange={handleReasonCodeChange}
                        disabled={!formData.selectedStatus}
                      >
                        <option value="">Select a Reason</option>
                        {exceptionReasonValues
                          .filter((reason) => {
                            return statusReasonMapping[
                              formData?.selectedStatus
                            ]?.includes(reason.code);
                          })
                          .map((reason, index) => {
                            return (
                              <option key={index} value={reason.code}>
                                {reason.name}
                              </option>
                            );
                          })}
                      </select>
                      <span
                        style={
                          errors.reasonCode.isValid
                            ? errorHelpTextStyle
                            : { display: "none" }
                        }
                      >
                        {errors.reasonCode.message}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="tab-footer">
            <div className="button-action">
              <div className="form-group">
                {/* <input type="button" className="btn-lightgray" value="Cancel" onClick={handleReset}></input> */}
                <input
                  type="submit"
                  form="StatusForm"
                  value="Save Changes"
                ></input>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ManagementTab;
