import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../header/Header";
import "react-loading-skeleton/dist/skeleton.css";
import moment from "moment";
import { useSelector } from "react-redux";
import EquipmentManagement from "./EquipmentManagement";
import FleetDetail from "./FleetDetail";

const ExceptionDetail = () => {
  const navigate = useNavigate();
  const { exceptionId } = useParams();
  const [fleet, setfleet] = useState({});
  const [exception, setException] = useState({});
  const fleets = useSelector((state) => state.dashboardReducer.fleets);
  const exceptions = useSelector((state) => state.dashboardReducer.exceptions);
  const recomandation = useSelector((state) => state.dashboardReducer.recommedations);
  useEffect(() => {
    const exceptionDetail = exceptions.find(
      (exception) => exception.id == exceptionId
    );
    if (exceptionDetail) {
      setException(exceptionDetail);
      const fleetDetail = fleets.find(
        (fleet) => fleet.asset_name == exceptionDetail.asset_name
      );
      if (fleetDetail) {
        setfleet(fleetDetail);
      }
    }
  }, [exceptions, fleets]);
  const [fleetImage, setFleetImage] = useState(
    `${window?.location?.origin}/images/fleet_images/dozers.png`
  );

  return (
    <React.Fragment>
      <div className="dash-header">
        <Header dateRangeFilter={[]} backdashboard/>
      </div>
      <FleetDetail fleet={fleet} exception={exception} recomandation={recomandation} isExceptionDetail />
      <div className="exception-management-tab">
        <EquipmentManagement
          selectedEquipment={fleet}
          selectedException={exception}
        />
      </div>
    </React.Fragment>
  );
};

export default ExceptionDetail;
