import moment from "moment";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import Popup from "reactjs-popup";
import { fetchExceptionDetails } from "../../../redux/exception-details/exceptionDetailsThunks";
import UpsertConMon from "../../ExceptionDetailsComponents/UpsertConMon/UpsertConMon";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { setExceptionData } from "../../../redux/v2/dashbaord/DashboardThunk";
import { useNavigate } from "react-router-dom";

let errorStyle = {
  border: "1px solid red",
};
let errorHelpTextStyle = {
  color: "red",
  marginLeft: "10px",
};

const addDays = (days) => {
  let DateObj = new Date();
  DateObj.setDate(DateObj.getDate() + days);
  return DateObj;
};

const statusReasonMapping = {
  S1: [],
  S2: ["R3", "R4", "R5", "R6", "R7"],
  S3: ["R0", "R4", "R5", "R8", "R9"],
  S4: [],
  S5: [],
};

export const DatePickerModal = ({
  setShowModal,
  showModal,
  exception,
  Status,
  onSubmit,
  type,
}) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    selectedDate: moment().format("YYYY-MM-DD"),
    reasonCode: "",
    otherReasonCode: "",
    exceptionId: "",
  });
  const [showOtherReasonCode, setShowOtherReasonCode] = useState(false);
  const [error, setError] = useState({
    selectedDate: { isValid: false, message: "" },
    reasonCode: { isValid: false, message: "" },
  });
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();

  // let exceptionReasonValues = useSelector(
  //   (state) => state.exceptionDetailsReducer.exceptionReasonValues
  // );
  let exceptionReasonValues = [
    { code: "R0", name: "AHS Review Required" },
    { code: "R3", name: "Monitoring" },
    { code: "R4", name: "Pending fixes by maint" },
    { code: "R5", name: "Early Sign/Intermettent" },
    { code: "R6", name: "Low priority issues" },
    { code: "R7", name: "Other" },
    { code: "R8", name: "Fault Cleared" },
    { code: "R9", name: "Next Service Soon" },
  ];

  let remsWatchlistRecords = useSelector(
    (state) => state.exceptionDetailsReducer.remsConmonWatchlistRecord
  );
  const [selectedConmon, setSelectedConmon] = useState();

  let selectedException = useSelector(
    (state) => state.exceptionDetailsReducer.selectedException
  );

  useEffect(() => {
    showModal?.exceptionId &&
      dispatch(fetchExceptionDetails(showModal?.exceptionId));
  }, [showModal]);

  // useEffect(() => {
  //  if(selectedConmon){
  //   MySwal.fire(
  //     "Updated!",
  //     "Exception status has been updated.",
  //     "success"
  //   );
  //  }
  // }, [selectedConmon]);

  useEffect(() => {
    if (selectedException?.length > 0) {
      setSelectedConmon(selectedException[0]?.rems_event_id);
      setFormData((formData) => {
        return {
          ...formData,
          exceptionId: selectedException[0].id,
          selectedStatus: selectedException[0].exception_status,
          reasonCode: selectedException[0].exception_status_reason_code,
          selectedDate:
            selectedException[0].exception_status_till_date ||
            moment().format("YYYY-MM-DD"),
          otherReasonCode:
            selectedException[0].exception_status_custom_reason_code,
        };
      });
    }
  }, [selectedException]);

  useEffect(() => {
    setFormData({
      selectedDate: moment().format("YYYY-MM-DD"),
      reasonCode: "",
      otherReasonCode: "",
    });
    setShowOtherReasonCode(false);
    setError({
      selectedDate: { isValid: false, message: "" },
      reasonCode: { isValid: false, message: "" },
    });
  }, [showModal]);

  const handleReasonCodeChange = (e) => {
    // if (e.target.value === "R4") {
    //   setShowOtherReasonCode(true);
    // } else {
    //   error.custom_reason_code && delete error.custom_reason_code;
    //   setShowOtherReasonCode(false);
    // }
    // if (e.target.value) {
    //   setError({
    //     ...error,
    //     reasonCode: {
    //       isValid: false,
    //       message: "",
    //     },
    //   });
    // }
    setFormData({
      ...formData,
      reasonCode: e.target.value,
      otherReasonCode: e.target.value,
    });
  };
  let Exceptions = useSelector((state) => state.dashboardReducer.exceptions);
  const onSubmitHandler = () => {
    onSubmit({
      ...formData,
      rems_event_id: selectedConmon,
      selectedDate: "",
      reasonCode: "",
    });
    const {
      rems_event_id,
      selectedStatus,
      oldStatus,
      reasonCode,
      otherReasonCode,
      toDate,
    } = formData;

    const data = {
      exception_id: selectedException.id,
      status: selectedStatus,
      old_status: oldStatus ? oldStatus : null,
      reason_code: reasonCode,
      custom_reason_code: otherReasonCode ? otherReasonCode : null,
      till_date: toDate ? toDate : null,
      user_id: null,
    };
    let isChange = false;
    debugger;
    if (Exceptions && Exceptions.length > 0) {
      let deepExceptions = JSON.parse(JSON.stringify(Exceptions));
      deepExceptions.forEach((item) => {
        if (item.id === exception.id) {
          isChange = true;
          if (Status) {
            item.exception_status = Status;
          }
          if (formData?.reasonCode) {
            item.exception_status_reason_code = formData.reasonCode;
          }
          if (formData?.selectedDate) {
            item.exception_status_till_date = toDate;
          }
        }
        return item;
      });
      if (isChange) {
        dispatch(setExceptionData(deepExceptions));
        MySwal.fire(
          "Updated!",
          "Exception status has been updated.",
          "success"
        );
        navigate("/");
      } else {
        MySwal.fire("Error!", "Exception status update Failed.", "error");
      }
    }
  };

  const validateForm = (submitted) => {
    const { selectedDate, reasonCode, otherReasonCode } = formData;
    let retData = {
      selectedDate: {
        isValid: false,
        message: "",
      },
      reasonCode: {
        isValid: false,
        message: "",
      },
    };
    if (submitted) {
      if (!selectedDate && ["S2"].includes(showModal.category)) {
        retData.selectedDate = {
          isValid: true,
          message: "This field is required.",
        };
      }
      if (!reasonCode) {
        retData.reasonCode = {
          isValid: true,
          message: "This field is required.",
        };
      } else if (reasonCode === "R4" && !otherReasonCode) {
        retData.custom_reason_code = {
          isValid: true,
          message: "This field is required.",
        };
      } else if (
        reasonCode &&
        !statusReasonMapping[showModal.category].includes(reasonCode)
      ) {
        retData.reasonCode = {
          isValid: true,
          message: "Plesase select appropriate reason.",
        };
      }
    }
    return retData;
  };

  return (
    <div
      className={`${
        showModal[type] ? "show" : ""
      } modal fade modal-center datePickerModal`}
      style={
        showModal[type] ? { display: "block", paddingRight: "17px" } : null
      }
    >
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
        }}
        onClick={() => {
          setShowModal(false);
        }}
      ></div>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header align-items-center">
            <h6 className="modal-title" id="exampleModalLabel">
              Select Status
            </h6>
            <button
              type="button"
              className="close"
              onClick={() => {
                setShowModal(false);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              {/* {showModal.category === "S1" ? (
                <>
                  <div className="anchor-link">
                    <Popup trigger={<a href="#">Link to existing entry in ConMon Watchlist</a>} modal contentStyle = {{width: '70%'}} nested>
                      {close => <UpsertConMon close={close} />}
                    </Popup>
                  </div>
                </>
              ) : null} */}

              {(showModal.category === "S2" || showModal.category === "S3") && (
                <>
                  <div className="form-group">
                    <label>Reason</label>
                    <div className="custom-select">
                      <select
                        value={formData.reasonCode}
                        // style={error.reasonCode.isValid ? errorStyle : {}}
                        onChange={handleReasonCodeChange}
                      >
                        {/* <option value="">Select</option>
                        <option value="severe">Severe</option>
                        <option value="abnormal">Abnormal</option>
                        <option value="moderate">Moderate</option>
                        <option value="healthy">Healthy</option>
                        <option value="not measured">Not Measured</option> */}
                        {exceptionReasonValues
                          .filter((reason) => {
                            return statusReasonMapping[
                              showModal.category
                            ]?.includes(reason.code);
                          })
                          ?.map((reason) => {
                            return (
                              <option value={reason.code} key={reason.code}>
                                {reason.name}
                              </option>
                            );
                          })}
                      </select>
                      <span
                        style={
                          error.reasonCode.isValid
                            ? errorHelpTextStyle
                            : { display: "none" }
                        }
                      >
                        {error.reasonCode.message}
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <span
                      style={showOtherReasonCode ? {} : { display: "none" }}
                    >
                      <label>Other Comment</label>
                      <div className="custom-input">
                        <input
                          style={
                            error.custom_reason_code &&
                            error.custom_reason_code.isValid
                              ? errorStyle
                              : {}
                          }
                          value={formData.otherReasonCode}
                          onChange={(e) => {
                            if (e.target.value) {
                              error.custom_reason_code &&
                                delete error.custom_reason_code;
                              setError(error);
                            }
                            setFormData({
                              ...formData,
                              otherReasonCode: e.target.value,
                            });
                          }}
                          placeholder="Enter Comment"
                        ></input>
                      </div>
                      <span
                        style={
                          error.custom_reason_code &&
                          error.custom_reason_code.isValid
                            ? errorHelpTextStyle
                            : { display: "none" }
                        }
                      >
                        {error.custom_reason_code
                          ? error.custom_reason_code.message
                          : ""}
                      </span>
                    </span>
                  </div>
                </>
              )}

              {showModal.category === "S2" && (
                <div className="form-group">
                  <span>
                    <label>Snooze Until</label>
                    <DatePicker
                      style={error.selectedDate.isValid ? {} : errorStyle}
                      selected={
                        Date.parse(formData.selectedDate) ||
                        Date.parse(new Date())
                      }
                      onChange={(date) => {
                        if (date) {
                          setError({
                            ...error,
                            selectedDate: { isValid: false, message: "" },
                          });
                        }
                        setFormData({ ...formData, selectedDate: date });
                      }}
                      minDate={new Date()}
                      maxDate={addDays(30)}
                      placeholderText="Select Date"
                      dateFormat="yyyy-MM-dd"
                    />
                    <span
                      style={
                        error.selectedDate.isValid
                          ? errorHelpTextStyle
                          : { display: "none" }
                      }
                    >
                      {error.selectedDate.isValid
                        ? error.selectedDate.message
                        : ""}
                    </span>
                  </span>
                </div>
              )}
              {showModal.category === "S1" && (
                <>
                  <div className="form-group">
                    <label>Active Exception</label>
                  </div>
                </>
              )}
              {showModal.category === "S5" && (
                <div className="form-group">
                  <label>Complete Exception</label>
                </div>
              )}
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-dark btn-sm"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={onSubmitHandler}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
