import React, { useState } from "react";
import "./MachineCard.css";

const MachineCard = ({ data, handleClick }) => {
  const [selectedId, setselectedId] = useState(null);
  return (
    <div className="machine-card">
      <div className="d-flex">
        <span className="except-heading">Machine</span>
        <div className="d-flex">
          <p>Count: </p>
          <b style={{marginLeft:"5px"}}> {data.length}</b>
        </div>
      </div>
      <div className="d-flex except-table-header">
        <p></p>
        <p>Machine</p>
        <p style={{ marginLeft: "10px" }}>Most Recent Exceptions</p>
        <p>Raised Count</p>
      </div>
      <div className="except-sub-heading">
        <table>
          <tbody className="machine-table-scrollbar">
            {data.map((obj) => {
              return (
                <tr
                  className="except-content"
                  onClick={(e) => {
                    e.stopPropagation();
                    setselectedId(obj.id);
                    handleClick && handleClick(obj.equipment_id);
                  }}
                >
                  <td
                    className={`severity-${
                      obj.event_severity ? obj.event_severity : "minor"
                    }`}
                  >
                    <div className="severity-box"></div>
                  </td>
                  <td
                    className={`bg-hover ${
                      obj.id == selectedId ? "selected" : ""
                    }`}
                  >
                    {obj.equipment_id}
                  </td>
                  <td
                    className={`bg-hover ${
                      obj.id == selectedId ? "selected" : ""
                    }`}
                  >
                    {obj.event_start_time}
                  </td>
                  <td
                    className={`bg-hover ${
                      obj.id == selectedId ? "selected" : ""
                    } machine-count-box`}
                  >
                    {obj.event_total_count}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MachineCard;
