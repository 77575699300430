import React from "react";
import "./ExceptionCard.css";

const ExceptionCard = ({ chartData, statusCount, heading, watchListData }) => {
  console.log("statusCount", statusCount);
  console.log("watchListData", watchListData);
  // const countArray = [];
  // const messageArray = [];

  // chartData.forEach((item) => {
  //   countArray.push(item.count);
  //   messageArray.push(item.name);
  // });
  const exceptionData = chartData.map((obj) => {
    return (
      <div
        className={`d-flex except-content1 ${
          obj.isSelectedException ? "selected" : "unselected"
        }`}
        style={{
          display: "flex",
          width: `${obj.percentage}% `,
        }}
      >
        <h5>{obj.count}</h5>
        <p>{obj.name}</p>
      </div>
    );
  });
  return (
    <div className="exception-card">
      <div>
        <span className="except-heading">{heading}</span>
      </div>
      {heading === "Exceptions" ? (
        <div className="d-flex except-count">
          <div>
            <icon style={{ backgroundColor: "#B2292E" }}>N</icon>
            <span>
              {statusCount.length > 0 && statusCount[0]
                ? statusCount[0]["count"]
                : 0}
            </span>
          </div>
          <div>
            <icon style={{ backgroundColor: "#F1C400" }}>A</icon>
            <span>
              {statusCount.length > 0 && statusCount[1]
                ? statusCount[1]["count"]
                : 0}
            </span>
          </div>
          <div>
            <icon style={{ backgroundColor: "#F1C400" }}>S</icon>
            <span>
              {statusCount.length > 0 && statusCount[4]
                ? statusCount[4]["count"]
                : 0}
            </span>
          </div>
          <div>
            <icon style={{ backgroundColor: "#B2292E" }}>C</icon>
            <span>
              {statusCount.length > 0 && statusCount[3]
                ? statusCount[3]["count"]
                : 0}
            </span>
          </div>
        </div>
      ) : (
        <div className="d-flex except-count">
          <div>
            <icon style={{ backgroundColor: "#B2292E" }}>N</icon>
            <span>
              {watchListData.length > 0 && watchListData[0]
                ? watchListData[0]["count"]
                : 0}
            </span>
          </div>
          <div>
            <icon style={{ backgroundColor: "#F1C400" }}>A</icon>
            <span>
              {watchListData.length > 0 && watchListData[1]
                ? watchListData[1]["count"]
                : 0}
            </span>
          </div>
          <div>
            <icon style={{ backgroundColor: "#F1C400" }}>S</icon>
            <span>
              {watchListData.length > 0 && watchListData[4]
                ? watchListData[4]["count"]
                : 0}
            </span>
          </div>
          <div>
            <icon style={{ backgroundColor: "#B2292E" }}>C</icon>
            <span>
              {watchListData.length > 0 && watchListData[3]
                ? watchListData[3]["count"]
                : 0}
            </span>
          </div>
        </div>
      )}
      <div
        className={`except-sub-heading chart-container${
          chartData.length > 8 ? "-scrollable" : ""
        }`}
      >
        <div>{exceptionData}</div>
      </div>
    </div>
  );
};

export default ExceptionCard;
