import Papa from "papaparse";
import { LocalStorageConstant } from "./constants";

export function functionCalExceptions(data) {
  // Generate Exception Count Data
  const countMap = {};
  data.forEach((item) => {
    const { event_template } = item;
    if (event_template) {
      if (!countMap[event_template]) {
        countMap[event_template] = 1;
      } else {
        countMap[event_template]++;
      }
    }
  });
  // Convert the countMap into the desired format
  const ExceptionData = Object.keys(countMap).map((event_template) => ({
    name: event_template,
    count: countMap[event_template],
  }));

  ExceptionData.sort((a, b) => b.count - a.count);

  // Calculate percentages
  ExceptionData.forEach((item, index) => {
    item.percentage = (item.count / ExceptionData[0].count) * 100;
    item.isSelectedException = false;
  });
  return ExceptionData;
}

export function functionCalWatchList(data) {
  // Generate Exception Count Data
  const countMap = {};
  data.forEach((item) => {
    const { WorkOrderTemplate } = item;
    if (WorkOrderTemplate) {
      if (!countMap[WorkOrderTemplate]) {
        countMap[WorkOrderTemplate] = 1;
      } else {
        countMap[WorkOrderTemplate]++;
      }
    }
  });
  // Convert the countMap into the desired format
  const ExceptionData = Object.keys(countMap).map((WorkOrderTemplate) => ({
    name: WorkOrderTemplate,
    count: countMap[WorkOrderTemplate],
  }));

  ExceptionData.sort((a, b) => b.count - a.count);

  // Calculate percentages
  ExceptionData.forEach((item, index) => {
    item.percentage = (item.count / ExceptionData[0].count) * 100;
    item.isSelectedException = false;
  });
  return ExceptionData;
}

export function functionCalExceptionStatus(data) {
  // Generate Exception Count Data
  const countMap = {};
  data.forEach((item) => {
    const { exception_status } = item;
    if (exception_status) {
      if (!countMap[exception_status]) {
        countMap[exception_status] = 1;
      } else {
        countMap[exception_status]++;
      }
    }
  });
  // Convert the countMap into the desired format
  const ExceptionStatus = Object.keys(countMap).map((exception_status) => ({
    name: exception_status,
    count: countMap[exception_status],
  }));

  return ExceptionStatus;
}

export function functionMachineException(data, exceptData, mahcineId) {
  const eventTemplates = data
    .filter((item) => item.equipment_id === mahcineId)
    .map((item) => item.event_template);
  exceptData.forEach((item) => {
    item.isSelectedException = false;
    if (eventTemplates.includes(item.name)) {
      item.isSelectedException = true;
    }
  });
  return exceptData;
}

export function functionMachineWatchList(data, exceptData, mahcineId) {
  const eventTemplates = data
    .filter((item) => item.Equipment === mahcineId)
    .map((item) => item.WorkOrderTemplate);

  exceptData.forEach((item) => {
    item.isSelectedException = false;
    if (eventTemplates.includes(item.name)) {
      item.isSelectedException = true;
    }
  });
  return exceptData;
}
export function functionCalSeverity(data) {
  // Generate Exception Count Data
  const countMap = {};
  data.forEach((item) => {
    const { event_severity } = item;
    if (event_severity) {
      if (!countMap[event_severity]) {
        countMap[event_severity] = 1;
      } else {
        countMap[event_severity]++;
      }
    }
  });
  // Convert the countMap into the desired format
  const status = Object.keys(countMap).map((event_severity) => ({
    name: event_severity,
    count: countMap[event_severity],
  }));
  return status;
}

export function getExceptionObject(id) {
  const csvData = JSON.parse(
    localStorage.getItem(LocalStorageConstant.DASHBOARD_CSV_DATA)
  );
  const desiredObject = csvData.find((item) => item.id == id);
  return desiredObject;
}

export function fetchExceptions(path) {
  const csvFilePath = "http://localhost:3000/csv/exceptions.csv";

  return new Promise((resolve, reject) => {
    Papa.parse(csvFilePath, {
      download: true,
      header: true,
      dynamicTyping: true,
      complete: (result) => {
        if (result.errors.length > 0) {
          reject(result.errors);
        } else {
          resolve(result.data);
        }
      },
      error: (error) => {
        reject(error.message);
      },
    });
  });
}

export function getMaintainanceData(csvData, assetId) {
  const assetExceptions = csvData.filter((item) => {return item.equipment_id == assetId});
  if (assetExceptions.length > 0) {
    return [assetExceptions[0], assetExceptions[1]];
  } else {
    return assetExceptions;
  }
}
