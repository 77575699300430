import { useParams } from "react-router-dom";

export const TopRecommendation = ({ asset, recomandation }) => {
  const { exceptionId } = useParams();
  

  const lastThreeData = recomandation.slice(-3);
  // let topRecommendations = [];
  // recomandation?.map((exception) => {
  //   return recomandation.map(
  //     (recommendation) =>
  //       recommendation &&
  //       topRecommendations.push({
  //         ...recommendation,
  //         total_critical: exception.total_critical,
  //       })
  //   );
  // });
  return (
    <div className="col-sm-12 col-md-6">
      <div className="top-boxes">
        <h6 className="d-flex align-items-center">
          <img
            className="mr-2"
            src={window.location.origin + "/images/recommendation-icon.svg"}
            alt=""
          />
          Top 3 Recommendations
        </h6>
        <div className="top-box d-flex" style={{justifyContent:'space-evenly', alignItems:'center'}}>
          {lastThreeData.slice(0, 3).map(
            (recomandation) =>
              recomandation.id && (
                <div key={recomandation.id} className="top-box-info">
                  <h5 className="orange d-flex" style={{justifyContent:'space-around', alignItems:'center'}}>
                    {recomandation.id}
                  </h5>
                  <p>{recomandation.title}</p>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};
