import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import timeline from 'highcharts/modules/timeline';
import highchartsMore from 'highcharts/highcharts-more';
import "./ExceptionChart.css"

// Load required Highcharts modules
highchartsMore(Highcharts);
timeline(Highcharts);

const Chart = ({ options, highcharts }) => (
  <HighchartsReact highcharts={highcharts} options={options} />
);

const options = {
  chart: {
    type: 'timeline',
  },

  title: {
   text: "Upcoming Planned Maintenance",
  },
  xAxis: {
    visible: false, // Hide the x-axis
  },
  yAxis: {
    visible: false, // Hide the y-axis
  },
  series: [{
    dataLabels: {
      allowOverlap: false,
      color: 'black',
      borderColor: 'white',
      backgroundColor: 'white',
      connectorWidth: 2,
      connectorColor: '#00AEC7',
      style: {
        textOutline: 0
      },
    },
    marker: {
      symbol: "url(http://localhost:3000/images/bell-yellow.svg)",
      width: 50,
      height: 20
    },
    data: [
      {
        x: Date.UTC(2023, 4, 28),
        
      },
      {
        x: (Date.UTC(2023, 5, 20),Date.UTC(2023, 5, 21)),
        name: 'Event Start',
        label: '20/5/23 10:23',
      },
      {
        x: Date.UTC(2023, 5, 21),
        name: 'Event closed',
        label: '21/5/23 10:23'
      },
      {
        x: Date.UTC(2023, 6, 10),
        name: 'Work Order',
        label: 'Date: 10/6/23',
        description: "",
        marker: {
          symbol: 'url(https://thenounproject.com/api/private/icons/4840200/edit/?backgroundShape=SQUARE&backgroundShapeColor=%23000000&backgroundShapeOpacity=0&exportSize=752&flipX=false&flipY=false&foregroundColor=%23000000&foregroundOpacity=1&imageFormat=png&rotation=0)',
          width: 20,
          height: 20,
        },
      },

      {
        x: (Date.UTC(2023, 6, 20),Date.UTC(2023, 6, 21)),
        name: 'Event start',
        label: 'Event start',
      },
      {
        x: Date.UTC(2023, 6, 21),
        name: 'Event closed',
        label: 'Event Closed',
      },
    ],
    lineWidth: 20
  },
  ],
};

export const ExceptionChart = () => {
  return (
    <div>
      <Chart options={options} highcharts={Highcharts} />
    </div>
  );
};