import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const FleetDetail = ({
  fleet,
  exception,
  isExceptionDetail,
  recomandation,
}) => {
  const navigate = useNavigate();
  const { exceptionId } = useParams();
  const [fleetImage, setFleetImage] = useState(
    `${window?.location?.origin}/images/fleet_images/dozers.png`
  );
  return (
    <>
      <div className="product-info-section">
        <div className="wrap">
          <div className="product-info-row">
            <div className="product-information">
              <div className="product-photo" style={{ cursor: "pointer" }}>
                <figure>
                  <span>{fleet?.serial_number}</span>
                  {fleetImage && <img src={fleetImage} alt="No Image"></img>}
                </figure>
              </div>
              <div className="product-info-cols">
                <div className="cols cols3">
                  <div className="col" style={{ cursor: "pointer" }}>
                    <ul
                      className="product-model-info"
                      style={{ height: "100%" }}
                    >
                      <li>
                        <span>Model:</span>
                        {fleet.model}
                      </li>
                      <li>
                        <span>Equipment Id:</span>
                        {fleet.asset_name}
                      </li>
                      <li>
                        <span>Fleet Type:</span>
                        {fleet.fleet_type}
                      </li>
                    </ul>
                  </div>
                  <div className="col">
                    <ul className="product-model-duration">
                      {/* <li><i className="icon-network"></i>25<sup>th</sup> Dec 2021 <span>|</span> 09:02 AM </li> */}
                      <li>
                        <i className="icon-network icon-color"></i>
                        {moment(exception.event_start_time).format(
                          "YYYY/MM/DD hh:mm a"
                        )}
                      </li>
                      <li>
                        <i className="icon-calendar icon-color"></i>
                        {moment(fleet.event_start_time).format(
                          "YYYY/MM/DD hh:mm a"
                        )}
                      </li>
                      <li>
                        <i className="icon-calendar icon-color"></i>
                        {moment(fleet.event_end_time).format(
                          "YYYY/MM/DD hh:mm a"
                        )}
                      </li>
                    </ul>
                  </div>
                  <div className="col">
                    <ul className="product-model-info">
                      <li>
                        <span></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="count-box">
                <span>Count</span>
                <h3>{recomandation?.length}</h3>
              </div>
            </div>
            <div className="recomandation">
              <a href="/#">
                <i className="icon-mail icon-color"></i>
              </a>
              {isExceptionDetail && (
                <a
                  className="pointer"
                  onClick={() =>
                    navigate(`/fleet/${exceptionId}`, {
                      state: { fleetId: exceptionId },
                    })
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 640 512"
                  >
                    <path d="M48 0C21.5 0 0 21.5 0 48V368c0 26.5 21.5 48 48 48H64c0 53 43 96 96 96s96-43 96-96H384c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V288 256 237.3c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H48zM416 160h50.7L544 237.3V256H416V160zM112 416a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm368-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                  </svg>
                  <span>view fleet</span>
                </a>
              )}
              {isExceptionDetail && (
                <a
                  className="pointer"
                  onClick={() =>
                    navigate(`/recommendation/${exceptionId}`, {
                      state: { exceptionId: exceptionId },
                    })
                  }
                >
                  <i className="icon-plus icon-color"></i>
                  <span>Recommendation</span>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FleetDetail;
