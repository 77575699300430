import React, { useEffect, useState } from "react";
import "./Header.css";
import Slider from "@mui/material/Slider";
import { LocalStorageConstant } from "../../../utils/constants";
import moment from "moment";
import {
  fetchConMonThunk,
  fetchExceptionsThunk,
  fetchFleetsThunk,
  fetchMachineEventThunk,
  fetchRecommedationThunk,
  fetchWorkOrdersThunk,
} from "../../../redux/v2/dashbaord/DashboardThunk";
import { CSVPath } from "../../../Data/allCSVPath";
import { useDispatch } from "react-redux";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useParams } from "react-router-dom";

function valuetext(value) {
  return `${value}°C`;
}

const Header = ({ dateRangeFilter, showDateFilter, BackArrow ,backdashboard}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { exceptionId } = useParams();

  const [dayFilter, setDayFilter] = useState([50, 180]);
  const marks = [
    {
      value: 1,
      label: "1 DAY",
    },
    {
      value: 180,
      label: "180 DAY",
    },
  ];
  const sliderStyles = {
    height: 15,
    "& .MuiSlider-thumb": {
      height: 24,
      width: 24,
      backgroundColor: "#fff",
      border: "2px solid #C5C5C5",
    },
    "& .MuiSlider-valueLabel": {
      backgroundColor: "#C5C5C5",
    },
  };
  useEffect(() => {
    const getDateRangeFilter = JSON.parse(
      localStorage.getItem(LocalStorageConstant.DASHBOARD_DATERANGE_FILTER)
    );
    if (getDateRangeFilter) {
      setDayFilter([...getDateRangeFilter]);
    }
  }, []);
  const handleDayFilterChange = (event, newValue) => {
    setDayFilter(newValue);
    dateRangeFilter(dayFilter);
  };

  const handleLoadData = () => {
    dispatch(fetchFleetsThunk(process.env.PUBLIC_URL + CSVPath.FleetList));
    dispatch(fetchExceptionsThunk(process.env.PUBLIC_URL + CSVPath.Exceptions));
    dispatch(
      fetchWorkOrdersThunk(process.env.PUBLIC_URL + CSVPath.WorkOrderList)
    );
    dispatch(fetchConMonThunk(process.env.PUBLIC_URL + CSVPath.ConMonList));
    dispatch(
      fetchMachineEventThunk(process.env.PUBLIC_URL + CSVPath.MachineEventList)
    );
    dispatch(
      fetchRecommedationThunk(
        process.env.PUBLIC_URL + CSVPath.RecommendationList
      )
    );
  };
  const backtopage = () => {
    navigate(`/exception-detail/${exceptionId}`);
    // navigate(`/`);
  };
  const backtopageexception = () => {
    // navigate(`/exception-detail/${exceptionId}`);
    navigate(`/`);
  };
  return (
    <>
      <div className="d-flex v1-header">
        <div className="v1-logo-section">
          <div className="v1-company-logo">
            <img
              src={window.location.origin + "/logo.svg"}
              width="184"
              height="60"
              alt="Fortescue"
            ></img>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <div className="v1-top-header">
            <div className="d-flex" style={{ marginLeft: "5px" }}>
              <div className="v1-fleet-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                >
                  <path
                    d="M20.8076 18.8228V13.7229H23.3555V18.8228H20.8076ZM16.9856 18.8228V8.63086H19.5334V18.8228H16.9856ZM9.34155 18.8228V8.63086H11.8896V18.8228H9.34155ZM5.51953 18.8228V13.7229H8.06763V18.8228H5.51953Z"
                    fill="#13294B"
                  />
                  <path
                    d="M13.1641 25.1921V22.6441H4.24609V20.0961H13.1641V4.80908H15.7122V20.0971H24.6301V22.6451H15.7122V25.1931L13.1641 25.1921Z"
                    fill="#418FDE"
                  />
                </svg>
              </div>
              <div className="text-nowrap asd">
                <h5>Fleet Detail</h5>
              </div>
              <div className="v1-search-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="32"
                  viewBox="0 0 33 32"
                  fill="none"
                >
                  <path
                    d="M21.6391 18.9688H20.5991L20.2301 18.6128C21.6739 16.9305 22.4099 14.7542 22.2834 12.5408C22.1568 10.3275 21.1777 8.24925 19.5516 6.74241C17.9254 5.23558 15.7787 4.41737 13.5622 4.45956C11.3456 4.50175 9.23163 5.40107 7.664 6.96869C6.09638 8.53631 5.19707 10.6503 5.15487 12.8669C5.11268 15.0834 5.93089 17.2301 7.43773 18.8563C8.94456 20.4824 11.0228 21.4615 13.2362 21.588C15.4495 21.7146 17.6258 20.9786 19.3081 19.5348L19.6641 19.9038V20.9438L26.2481 27.5138L28.2101 25.5518L21.6391 18.9688ZM13.7391 18.9688C12.5672 18.9688 11.4217 18.6213 10.4473 17.9702C9.47297 17.3192 8.71355 16.3938 8.2651 15.3112C7.81665 14.2285 7.69931 13.0372 7.92793 11.8879C8.15655 10.7385 8.72085 9.68279 9.54948 8.85417C10.3781 8.02554 11.4338 7.46124 12.5832 7.23262C13.7325 7.004 14.9238 7.12134 16.0065 7.56979C17.0891 8.01824 18.0145 8.77766 18.6655 9.75202C19.3166 10.7264 19.6641 11.8719 19.6641 13.0438C19.6651 13.8222 19.5126 14.5931 19.2152 15.3124C18.9178 16.0317 18.4814 16.6853 17.931 17.2357C17.3806 17.7861 16.7271 18.2225 16.0077 18.5199C15.2884 18.8173 14.5175 18.9698 13.7391 18.9688Z"
                    fill="#333F48"
                  />
                </svg>
              </div>
              <div className="question-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="32"
                  viewBox="0 0 33 32"
                  fill="none"
                >
                  <path
                    d="M15.4369 23.2381H17.8289V20.8461H15.4369V23.2381ZM16.6329 4.10107C14.2672 4.10107 11.9547 4.80257 9.9877 6.11687C8.02073 7.43116 6.48766 9.29921 5.58236 11.4848C4.67706 13.6704 4.44019 16.0753 4.90171 18.3955C5.36323 20.7158 6.5024 22.847 8.17518 24.5198C9.84795 26.1926 11.9792 27.3317 14.2994 27.7932C16.6196 28.2548 19.0246 28.0179 21.2102 27.1126C23.3957 26.2073 25.2638 24.6742 26.5781 22.7073C27.8924 20.7403 28.5939 18.4277 28.5939 16.0621C28.5928 12.8901 27.3323 9.84843 25.0894 7.60554C22.8465 5.36265 19.8048 4.10213 16.6329 4.10107ZM16.6329 25.6311C14.7403 25.6311 12.8902 25.0699 11.3166 24.0184C9.74301 22.967 8.51653 21.4725 7.79228 19.724C7.06802 17.9755 6.87852 16.0515 7.24775 14.1953C7.61697 12.3391 8.52833 10.634 9.86658 9.29577C11.2048 7.95752 12.9099 7.04616 14.7661 6.67694C16.6223 6.30772 18.5463 6.49722 20.2948 7.22147C22.0433 7.94573 23.5378 9.17221 24.5892 10.7458C25.6407 12.3194 26.2019 14.1695 26.2019 16.0621C26.1987 18.599 25.1895 21.031 23.3957 22.8249C21.6018 24.6187 19.1698 25.6279 16.6329 25.6311ZM16.6329 8.88607C16.0046 8.88594 15.3824 9.00959 14.802 9.24997C14.2215 9.49034 13.6941 9.84272 13.2498 10.287C12.8055 10.7312 12.4531 11.2587 12.2128 11.8392C11.9724 12.4196 11.8487 13.0418 11.8489 13.6701H14.2409C14.2409 13.0357 14.4929 12.4273 14.9415 11.9787C15.3901 11.5301 15.9985 11.2781 16.6329 11.2781C17.2673 11.2781 17.8757 11.5301 18.3243 11.9787C18.7729 12.4273 19.0249 13.0357 19.0249 13.6701C19.0249 16.0621 15.4369 15.7631 15.4369 19.6501H17.8289C17.8289 16.9591 21.4169 16.6601 21.4169 13.6701C21.4171 13.0417 21.2936 12.4194 21.0533 11.8389C20.813 11.2583 20.4606 10.7307 20.0163 10.2863C19.5721 9.84197 19.0446 9.4895 18.464 9.24906C17.8835 9.00863 17.2612 8.88594 16.6329 8.88607Z"
                    fill="#333F48"
                  />
                </svg>
              </div>
              <span className="v1-header-timestamp-label">Updated on</span>
              <span style={{ marginTop: "5px" }}>
                {moment().format("MMMM Do YYYY, h:mm:ss a")}
              </span>
              <div className="v1-menu-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M5.33301 10.666H10.666V5.33301H5.33301V10.666ZM13.333 26.666H18.666V21.333H13.333V26.666ZM5.33301 26.666H10.666V21.333H5.33301V26.666ZM5.33301 18.666H10.666V13.333H5.33301V18.666ZM13.333 18.666H18.666V13.333H13.333V18.666ZM21.333 5.33301V10.666H26.666V5.33301H21.333ZM13.333 10.666H18.666V5.33301H13.333V10.666ZM21.333 18.666H26.666V13.333H21.333V18.666ZM21.333 26.666H26.666V21.333H21.333V26.666Z"
                    fill="#333F48"
                  />
                </svg>
              </div>
              <div className="v1-notification-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                >
                  <path
                    d="M18.3652 32.625C19.1602 32.6226 19.9218 32.3058 20.4839 31.7437C21.046 31.1816 21.3629 30.4199 21.3652 29.625H15.3652C15.3676 30.4199 15.6844 31.1816 16.2465 31.7437C16.8086 32.3058 17.5703 32.6226 18.3652 32.625ZM27.3652 23.625V16.125C27.3652 11.525 24.9202 7.665 20.6152 6.645V5.625C20.6152 5.02826 20.3782 4.45597 19.9562 4.03401C19.5343 3.61205 18.962 3.375 18.3652 3.375C17.7685 3.375 17.1962 3.61205 16.7742 4.03401C16.3523 4.45597 16.1152 5.02826 16.1152 5.625V6.645C11.8252 7.665 9.36523 11.505 9.36523 16.125V23.625L6.36523 26.625V28.125H30.3652V26.625L27.3652 23.625ZM24.3652 25.125H12.3652V16.125C12.3652 12.405 14.6302 9.375 18.3652 9.375C22.1002 9.375 24.3652 12.405 24.3652 16.125V25.125Z"
                    fill="#333F48"
                  />
                </svg>
              </div>
              <div className="">
                <button className="v1-load-button" onClick={handleLoadData}>
                  Load
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex v1-bottom-header">
            <div
              className="d-flex inner-v1-bottom-header"
              style={{ alignItems: "centerx" }}
            >
              {BackArrow && (
                <div className="backtoback" onClick={() => backtopage()}>
                  <KeyboardBackspaceIcon />
                </div>
              )}
               {backdashboard && (
                <div className="backtoback" onClick={() => backtopageexception()}>
                  <KeyboardBackspaceIcon />
                </div>
              )}
              
              <span
                style={{
                  marginLeft: "5px",
                }}
              >
                Currently Viewing:
              </span>
              <p
                style={{
                  marginLeft: "5px",
                  color: "#418FDE",
                }}
              >
                FMG CCM CB EW SM & 793F 789C 785D 785D 793F 793F...
              </p>
            </div>
            <div className="v1-link-icon">
              {/* {showDateFilter && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <g filter="url(#filter0_d_1_9144)">
                    <path
                      d="M26 2H6C4.34315 2 3 3.34315 3 5V25C3 26.6569 4.34315 28 6 28H26C27.6569 28 29 26.6569 29 25V5C29 3.34315 27.6569 2 26 2Z"
                      fill="white"
                    />
                  </g>
                  <path
                    d="M7.54508 19.9998L8.95508 21.4098L12.3651 17.9998L10.9551 16.5898L7.54508 19.9998ZM19.9551 10.4998V13.9998H14.3651L8.95508 8.58984L7.54508 9.99984L13.5451 15.9998H19.9551V19.4998L24.4551 14.9998L19.9551 10.4998Z"
                    fill="#0274B5"
                  />
                  <defs>
                    <filter
                      id="filter0_d_1_9144"
                      x="0"
                      y="0"
                      width="32"
                      height="32"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="1" />
                      <feGaussianBlur stdDeviation="1.5" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1_9144"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1_9144"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              )} */}
            </div>
            <div className="v1-range-filter">
              {showDateFilter && (
                <Slider
                  sx={sliderStyles}
                  style={{
                    color: "#13294B",
                  }}
                  getAriaLabel={() => "Exception Date"}
                  value={dayFilter}
                  min={1}
                  max={180}
                  onChange={handleDayFilterChange}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  color="secondary"
                  marks={marks}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
