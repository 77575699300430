import React, { useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useDispatch } from "react-redux";
import ManagementTab from "./ManagementTab";
import WorkOrderTab from "./WorkOrderTab";

import "./ExceptionTab.css";
import MachineEvent from "./machine_event/MachineEvent";
import ConMon from "./conmon/ConMon";
import Pivision from "./pivision/Pivision";

const ExceptionTab = ({ selectedEquipment, selectedException }) => {
  return (
    <Tabs>
      <TabList id="main-list">
        <Tab>PIVision</Tab>
        <Tab>Management</Tab>
        <Tab>Work Orders</Tab>
        <Tab>Machine Events</Tab>
        <Tab>Manual Con-Mon Data</Tab>
      </TabList>

      <TabPanel>
        <Pivision />
      </TabPanel>
      <TabPanel>
        <ManagementTab
          selectedEquipment={selectedEquipment}
          selectedException={selectedException}
        />
      </TabPanel>
      <TabPanel>
        <WorkOrderTab
          selectedEquipment={selectedEquipment}
          selectedException={selectedException}
        />
      </TabPanel>
      <TabPanel>
        <MachineEvent selectedEquipment={selectedEquipment} />
      </TabPanel>
      <TabPanel>
        <ConMon selectedEquipment={selectedEquipment} />
      </TabPanel>
    </Tabs>
  );
};

export default ExceptionTab;
