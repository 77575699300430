import React, { useEffect, useState } from "react";
import { CommonWorkOrder } from "../exception_tab/work_order/CommonWorkOrder";
import { useSelector } from "react-redux";
import { WorkOrderTable } from "../../ExceptionDetailsComponents/workOrderTableComponent";
const WorkOrderTab = ({ selectedEquipment, selectedException }) => {
  const [tecoOrders, setTecoOrders] = useState([]);
  const [planOrders, setPlanOrders] = useState([]);

  const work_orders = useSelector(
    (state) => state.dashboardReducer.work_orders
  );
  useEffect(() => {
    const tecoOrder1 = work_orders.filter(
      (work_order) =>
        work_order.asset_name === selectedEquipment.asset_name &&
        work_order.Status == "TECO"
    );
    setTecoOrders(tecoOrder1);
    const planOrder1 = work_orders.filter(
      (work_order) =>
        work_order.Equipment === selectedEquipment.asset_name &&
        work_order.Status == "Planned"
    );
    setPlanOrders(planOrder1);
  }, [work_orders]);

  return (
    <>
      <div className="tab-main-content">
        <div className="work-order-teco">
          <CommonWorkOrder data={tecoOrders} tableTitle="Teco Orders" />
        </div>
        <div className="work-order-plan">
          <CommonWorkOrder data={planOrders} tableTitle="Planned Orders" />
        </div>
      </div>
    </>
  );
};

export default WorkOrderTab;
