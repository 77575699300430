import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../header/Header";
import { useSelector } from "react-redux";
import FleetDetail from "../exceptions/FleetDetail";
import { TopRecommendation } from "./TopRecommendation";
import { TopFaultCodes } from "./TopFaultCodes";
import { ConsumableRemainingLife } from "./ConsumableRemainingLife";
import { RecommendationsTable } from "./RecommendationsTable";
import { CategorywiseTables } from "../../SummaryPageComponent";

const Fleet = () => {
  const { fleetId } = useParams();
  const { exceptionId } = useParams();
  const [fleet, setfleet] = useState({});
  const [exception, setException] = useState({});
  const [recommedations, setRecommedations] = useState([]);
  const fleets = useSelector((state) => state.dashboardReducer.fleets);
  const exceptions = useSelector((state) => state.dashboardReducer.exceptions);
  const recomandation = useSelector(
    (state) => state.dashboardReducer.recommedations
  );
  useEffect(() => {
    const recList = recomandation.filter((item) => {
      return item.exception_id == fleetId;
    });
    if (recList.length > 0) {
      setRecommedations([...recList]);
    }
  }, [recomandation]);
  useEffect(() => {
    const exceptionDetail = exceptions.find(
      (exception) => exception.id == fleetId
    );
    if (exceptionDetail) {
      setException(exceptionDetail);
      const fleetDetail = fleets.find(
        (fleet) => fleet.asset_name == exceptionDetail.asset_name
      );
      if (fleetDetail) {
        setfleet(fleetDetail);
      }
    }
  }, [exceptions, fleets]);
  const setSearchParams = () => {
  };

  return (
    <>
      <div className="dash-header">
        <Header dateRangeFilter={[]} BackArrow/>
      </div>
      <FleetDetail
        fleet={fleet}
        exception={exception}
        recomandation={recomandation}
      />
      <div id="main" className="fleet-details">
        <>
          <div className="recommendation-section">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="row mx-n5">
                  <TopRecommendation
                    asset={fleet}
                    recomandation={recommedations}
                    exceptionId={fleetId}
                  />
                  <TopFaultCodes asset={fleet} />
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <ConsumableRemainingLife />
              </div>
            </div>
            <CategorywiseTables
              logoImage="fault-code"
              heading="Fault Codes"
              data={exceptions}
              type={"fault-codes"}
              setSearchParams={setSearchParams}
              hideEquipmentType
              hideModel
              hideEventStatus
              hideTillDate
              hideActions
              hideStatus
            />
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <RecommendationsTable
                  // fleet={fleet}
                  // exception={exception}
                  recomandation={recommedations}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                {/* <div className="row recommendation-upload mt-0">
                    <Comments />
                    <UploadFileComponent
                      previousSelectedItem={uploadedFleetDocuments}
                      handleFileSelect={handleFileUpdload}
                      handleDeleteRow={handlePreviousRowDelete}
                      handleDownload={handleDownload}
                    />
                  </div> */}
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default Fleet;
