import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../components/v2/dashboard/Dashboard";
import ExceptionDetail from "../components/v2/exceptions/ExceptionDetail";
import Recommendation from "../components/v2/Recommendation/index";
import Fleet from "../components/v2/fleet/Fleet";


const FmgRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/" exact element={<Navigate to="/" replace />} /> */}
      <Route
        path="/exception-detail/:exceptionId"
        element={<ExceptionDetail />}
      />
      <Route path="/" element={<Dashboard />} />
      <Route path="/recommendation/:exceptionId" element={<Recommendation />} />
      <Route path="/fleet/:fleetId" element={<Fleet />} />
    </Routes>
  );
};

export default FmgRoutes;
