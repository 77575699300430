import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ConMonTable } from "./ConMonTable";

const ConMon = ({ selectedEquipment }) => {
  const [conMonData, setConMonData] = useState([]);
  const data = useSelector((state) => state.dashboardReducer.conmon);

  useEffect(() => {
    const filteredConMon = data.filter((item) => {
      return item.AssetID == selectedEquipment.asset_name;
    });
    setConMonData(filteredConMon);
  }, [data]);

  return (
    <Fragment>
      <div className="tab-main-content">
        <h6>ComMon Data</h6>
        <table className="table con-mon-table">
          <thead>
            <tr>
              <th>Measurement Point</th>
              <th>Measurement</th>
              <th>Units</th>
              <th>Date Measured</th>
              <th>SMH</th>
            </tr>
          </thead>
          <tbody>
            {conMonData.map((item) => {
              return (
                <tr>
                  <td>{item.MeasurementPoint}</td>
                  <td>{item.Measurement}</td>
                  <td>{item.Units}</td>
                  <td>{item.Date}</td>
                  <td>{item.SMU}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* {conMonData.length > 0 ? (
          <div className="responsive-table custom-scroll con-mon-tab">
            <ConMonTable tableData={conMonData} />
          </div>
        ) : (
          <div>No ConMon Data</div>
        )} */}
      </div>
    </Fragment>
  );
};

export default ConMon;
