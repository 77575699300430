import React, { useEffect, useState } from "react";
import "./MaintainanceChart.css";
const MaintainanceChart = ({ data }) => {
  const [workOrderDate, setWorkOrderDate] = useState([]);
  var RandomNumber = Math.floor(Math.random() * (40 - 10 + 1)) + 15;
  function RandomTime() {
    var randomTime = Math.floor(Math.random() * (40 - 10 + 1)) + 15;
    return randomTime;
  }
  useEffect(() => {
    if (data && data[0] && data[1]) {
      var initialDate1 = new Date(data[1].event_end_time);
      var initialDate2 = new Date(data[0].event_end_time);
      var next3rdDay1 = new Date(initialDate1);
      next3rdDay1.setDate(initialDate1.getDate() + 3);
      var next3rdDay2 = new Date(initialDate2);
      next3rdDay2.setDate(initialDate2.getDate() + 3);
      var next3rdDayStr1 =
        next3rdDay1.getMonth() +
        1 +
        "/" +
        next3rdDay1.getDate() +
        "/" +
        next3rdDay1.getFullYear() +
        " " +
        next3rdDay1.getHours() +
        ":" +
        RandomTime();

      var next3rdDayStr2 =
        next3rdDay2.getMonth() +
        1 +
        "/" +
        next3rdDay2.getDate() +
        "/" +
        next3rdDay2.getFullYear() +
        " " +
        next3rdDay2.getHours() +
        ":" +
        RandomTime();

      setWorkOrderDate([next3rdDayStr1, next3rdDayStr2]);
    }
  }, [data]);

  return (
    <>
      <div className="maintainance-chart">
        <div className="chart-heading">
          <h6>Upcoming Planned Maintenance</h6>
          {data && data.length > 0 && data[0] && data[1] && (
            <p>
              Date : {data[0].event_start_time} To {data[1].event_end_time}
            </p>
          )}
        </div>
        {data && data.length > 0 && data[0] && data[1] ? (
          <div className="chart-content">
            <div className="top"></div>
            <div className="chart-body">
              <div className="order-stack"></div>
              <div
                className="notification-stack"
                style={{ width: `${RandomNumber}%` }}
              >
                <div class="start-line"></div>
                <div class="start-box">
                  <b>Event Start</b>
                  <p>{data[1].event_start_time}</p>
                </div>
                <div className="exception-content">
                  <b>Exceptions</b>
                  <p>{data[1].event_template}</p>
                </div>

                <div className="event-warning"></div>
                <div class="end-line"></div>
                <div class="close-box">
                  <b>Event Closed</b> <p>{data[1].event_end_time}</p>
                </div>
              </div>
              <div className="order-stack">
                <div className="order-planned">
                  <div class="order-line"></div>
                  <div class="order-box">
                    <b>Work Order</b> <p>{workOrderDate[0]}</p>
                  </div>
                </div>
              </div>
              <div
                className="notification-stack"
                style={{ width: `${RandomNumber - 4}%` }}
              >
                <div class="start-line"></div>
                <div class="start-box">
                  <b>Event Start</b>
                  <p>{data[0].event_start_time}</p>
                </div>
                <div className="exception-content">
                  <b>Exceptions</b>
                  <p>{data[0].event_template}</p>
                </div>
                <div className="event-danger"></div>
                <div class="end-line"></div>
                <div class="close-box">
                  <b>Event Closed</b> <p>{data[0].event_end_time}</p>
                </div>
              </div>
              <div className="order-stack">
                <div className="order-unplanned">
                  <div class="order-line"></div>
                  <div class="order-box">
                    <b>Work Order</b> <p>{workOrderDate[1]}</p>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
            <div className="bottom"></div>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <span>No Data</span>
          </div>
        )}
      </div>
    </>
  );
};
export default MaintainanceChart;
