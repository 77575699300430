import Moment from "react-moment";

export const RecommendationsTable = ({ recomandation }) => {
 
  return (
    <div className="related-recommendations">
      <div className="exception-popup-heading d-flex justify-content-between">
        <h6 className="d-flex align-items-center">
          <img
            src={window.location.origin + "/images/Recommendation-icon.svg"}
            alt="Recommendations"
          />
          Recommendations
        </h6>
      </div>
      <div className="responsive-table ">
        <table className="table res-table">
          <tbody>
            <tr>
              <th>Recommendation</th>
              <th>Date </th>
              <th>Status</th>
              <th>Word Order</th>
              <th>Parts Cost</th>
              <th>Production Cost</th>
              <th>Labour Cost</th>
              <th></th>
            </tr>

            {recomandation && recomandation.length > 0 ? (
              recomandation?.map((recommendation) => (
                <tr key={recommendation.id}>
                  <td>{recommendation.title}</td>
                  <td>
                    <Moment format="YYYY/MM/DD h:mm a">
                      {recommendation.created_at}
                    </Moment>
                  </td>
                  <td>{recommendation.severity}</td>
                  <td>
                  {recommendation.workorder_number}
                  </td>
                  <td>{recommendation.cost_avoidance_parts}</td>   
                  <td>{recommendation.cost_avoidance_production}</td>   
                  <td>{recommendation.cost_avoidance_labour}</td>   
              
                </tr>
              ))
            ) : (
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>No Data</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
