import { createSlice } from "@reduxjs/toolkit";
import {
  fetchConMonThunk,
  fetchExceptionsThunk,
  fetchFleetsThunk,
  fetchMachineEventThunk,
  fetchRecommedationThunk,
  fetchWorkOrdersThunk,
  setExceptionData,
  setRecomendationData,
} from "./DashboardThunk";

export const DashboardSlice = createSlice({
  name: "DashboardSlice",
  initialState: {
    exceptions: [],
    fleets: [],
    work_orders: [],
    machine_events: [],
    conmon: [],
    recommedations: [],
  },
  reducers: {},
  extraReducers: {
    [fetchExceptionsThunk.fulfilled]: (state, { payload }) => {
      return { ...state, exceptions: payload };
    },
    [fetchFleetsThunk.fulfilled]: (state, { payload }) => {
      return { ...state, fleets: payload };
    },
    [fetchWorkOrdersThunk.fulfilled]: (state, { payload }) => {
      return { ...state, work_orders: payload };
    },
    [fetchMachineEventThunk.fulfilled]: (state, { payload }) => {
      return { ...state, machine_events: payload };
    },
    [fetchRecommedationThunk.fulfilled]: (state, { payload }) => {
      return { ...state, recommedations: payload };
    },
    [fetchConMonThunk.fulfilled]: (state, { payload }) => {
      return { ...state, conmon: payload };
    },
    [setExceptionData.fulfilled]: (state, { payload }) => {
      return { ...state, exceptions: payload };
    },
    [setRecomendationData.fulfilled]: (state, { payload }) => {
      return { ...state, recommedations: payload };
    },
  },
});

export default DashboardSlice.reducer;
